const imagesData = {
  //Home Page
  home1:
    "https://res.cloudinary.com/defku18yc/image/upload/v1695920099/DSC_5080-min_wwu8lt.jpg",
  home2:
    "https://res.cloudinary.com/defku18yc/image/upload/v1695920106/DSC_4526-min_ag5sbe.jpg",
  home3:
    "https://res.cloudinary.com/defku18yc/image/upload/v1687873498/Arya%20Samaj%20New/WhatsApp_Image_2023-06-26_at_9.51.54_PM_qimp4p.jpg",

  //About Us Page
  about1:
    "https://res.cloudinary.com/defku18yc/image/upload/v1688474040/Arya%20Samaj%20New/3_zbdaiu.png",

  //Vision and Mission
  vision:
    "https://res.cloudinary.com/defku18yc/image/upload/v1688474041/Arya%20Samaj%20New/4_ia92op.png",

  //Principal page
  principal:
    "https://res.cloudinary.com/defku18yc/image/upload/v1688474041/Arya%20Samaj%20New/5_d7bw5k.png",

  //Achievements
  achievement1:
    "https://res.cloudinary.com/defku18yc/image/upload/v1696266149/1_aowgpf.png",
  achievement2:
    "https://res.cloudinary.com/defku18yc/image/upload/v1696266149/2_swr19m.png",
  achievement3:
    "https://res.cloudinary.com/defku18yc/image/upload/v1696266149/3_lp8usz.png",
  achievement4:
    "https://res.cloudinary.com/defku18yc/image/upload/v1696664272/4_glnuja.png",

  //Contact us
  contact:
    " https://res.cloudinary.com/defku18yc/image/upload/v1688404329/2_xg6bfm.png",
  //Faculty
  faculty:
    "https://res.cloudinary.com/defku18yc/image/upload/v1697882722/About_us_pwkutb.png",
  //Admission
  addmission:
    "https://res.cloudinary.com/defku18yc/image/upload/v1688471025/1_dxknnt.png",

  //Facilities
  facilities:
    "https://res.cloudinary.com/defku18yc/image/upload/v1688474041/Arya%20Samaj%20New/2_jbdyk9.png",
  facilities1:
    "https://res.cloudinary.com/defku18yc/image/upload/v1677997360/background_qplcbi.jpg",
  facilities2:
    "https://res.cloudinary.com/defku18yc/image/upload/v1687873513/Arya%20Samaj%20New/20230626_135532_g0vxaz.jpg",
  facilities3:
    "https://res.cloudinary.com/defku18yc/image/upload/v1677997362/Staff_bainhu.jpg",
  facilities4:
    "https://res.cloudinary.com/defku18yc/image/upload/v1687873507/Arya%20Samaj%20New/20230626_135425_nhhxmj.jpg",
  facilities5:
    "https://res.cloudinary.com/defku18yc/image/upload/v1695952248/keren-fedida-FrhG7ogEMCY-unsplash-min_mb2qm0.jpg",
  facilities6:
    "https://res.cloudinary.com/defku18yc/image/upload/v1695952806/jannes-glas-0NaQQsLWLkA-unsplash-min_ggy0nd.jpg",

  //Banner
  banner:
    "https://res.cloudinary.com/defku18yc/image/upload/v1688470237/Black_Minimalist_Fashion_Store_Twitter_Ad_pavuaf.png",

  //Gallery
  gallery1:
    " https://res.cloudinary.com/defku18yc/image/upload/v1687873498/Arya%20Samaj%20New/WhatsApp_Image_2023-06-26_at_9.51.55_PM_1_q1icso.jpg",
  gallery2:
    "https://res.cloudinary.com/defku18yc/image/upload/v1687873497/Arya%20Samaj%20New/WhatsApp_Image_2023-06-26_at_9.51.55_PM_fps45u.jpg",
  gallery3:
    "https://res.cloudinary.com/defku18yc/image/upload/v1687873504/Arya%20Samaj%20New/20230626_135848_rz7kg7.jpg",
  gallery4:
    "https://res.cloudinary.com/defku18yc/image/upload/v1695920107/DSC_5091-min_km9j7v.jpg",
  gallery5:
    "https://res.cloudinary.com/defku18yc/image/upload/v1695921130/DSC_4894-min_xobgd6.jpg",
  gallery6:
    "https://res.cloudinary.com/defku18yc/image/upload/v1695920103/DSC_4545-min_grnimv.jpg",
  gallery7:
    "https://res.cloudinary.com/defku18yc/image/upload/v1695920102/DSC_4854-min_zictlh.jpg",
  gallery8:
    "https://res.cloudinary.com/defku18yc/image/upload/v1695920101/DSC_5084-min_unsj9j.jpg",
  gallery9:
    "https://res.cloudinary.com/defku18yc/image/upload/v1695920101/DSC_4988-min_swjgat.jpg",
  gallery10:
    "https://res.cloudinary.com/defku18yc/image/upload/v1695921128/DSC_4703-min_o47nyz.jpg",
  gallery11:
    "https://res.cloudinary.com/defku18yc/image/upload/v1695920098/DSC_5124-min_vkgsfg.jpg",
  gallery12:
    "https://res.cloudinary.com/defku18yc/image/upload/v1695920098/DSC_5124-min_vkgsfg.jpg",
  gallery13:
    "https://res.cloudinary.com/defku18yc/image/upload/v1695920098/DSC_5099-min_pqdchm.jpg",
  gallery14:
    "https://res.cloudinary.com/defku18yc/image/upload/v1695920098/DSC_4448-min_bhqkld.jpg",
  gallery15:
    "https://res.cloudinary.com/defku18yc/image/upload/v1695921130/DSC_4894-min_xobgd6.jpg",
  gallery16:
    "https://res.cloudinary.com/defku18yc/image/upload/v1695921128/DSC_4703-min_o47nyz.jpg",
  mandatoryDoc:
    "https://res.cloudinary.com/defku18yc/image/upload/v1703865108/About_us_1_ldpxxg.png",
  doc: "https://res.cloudinary.com/defku18yc/image/upload/v1703867341/icons8-document-400_1_uhwugx.png",
};

export default imagesData;
