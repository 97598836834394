import React, { useState } from "react";
import "../about/about.css";
import { Container, Col, Image, Form, Button, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import imagesData from "../../helper/imagesData";
import {
  toastSuccess,
  smtpJs,
  toastFailed,
  ToastContainerO,
  aboutPageData,
} from "../../helper/helper";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../components/spinner/Loader";
import useMoveToTop from "../../helper/moveToTop";
import { CardComp } from "../../components";

export default function Contact() {
  let navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    query: "",
  });

  useMoveToTop();

  const handleOnChange = (e, field) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  const handleSubmit = (event) => {
    setSubmit(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    const sub = `Mail from Website contact section`;
    let body = `
      Name: ${formData.name} <br/>
      from : ${formData.email}  <br/>
      phone number : ${formData.phone}  <br/>
      Query : ${formData.query}`;

    smtpJs(sub, body)
      .then((message) => {
        toastSuccess(message);
      })
      .catch((err) => {
        toastFailed();
      })
      .finally(() => {
        setSubmit(false);
        setTimeout(() => {
          navigate("/");
        }, 3000);
      });
  };

  return (
    <div className="about">
      <div className="titleContainer">
        <h4 className="title">Contact Us</h4>
      </div>
      <Container fluid="md">
        <Col className="imageCH">
          <Image src={imagesData.contact} thumbnail className="img"></Image>
        </Col>

        <Row>
          <h5 style={{ textAlign: "center", color: "var(--primary)" }}>
            Share us your feedback
          </h5>
        </Row>
        <Form validated={validated} onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your name"
              required
              onChange={(e) => handleOnChange(e, "name")}
              value={formData.name}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="phone"
              placeholder="9912345678"
              required
              value={formData.phone}
              onChange={(e) => handleOnChange(e, "phone")}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="example@gmail.com"
              value={formData.email}
              onChange={(e) => handleOnChange(e, "email")}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Query</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              placeholder="Enter your Query"
              value={formData.query}
              onChange={(e) => handleOnChange(e, "query")}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit" disabled={submit}>
            {submit ? <Loader /> : "Submit"}
          </Button>
        </Form>
        <br />
        <br />
        <br />
        <br />
        <Col>
          <Row xs={1} md={2} className="g-4">
            {aboutPageData.map((item, index) => {
              return <CardComp key={index} item={item} />;
            })}
          </Row>
        </Col>
        <br />
      </Container>
      <ToastContainerO />
    </div>
  );
}
