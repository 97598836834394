import React from "react";
import { Spinner } from "react-bootstrap";

export default function Loader() {
  return (
    <Spinner
      as="span"
      animation="grow"
      size=""
      role="status"
      aria-hidden="true"
    />
  );
}
