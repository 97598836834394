import React from "react";
import { Button, Modal, Image } from "react-bootstrap";
import imagesData from "../../helper/imagesData";

export default function BannerModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      // aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h4 className="primaryTextColor">Admission Open</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body onClick={props.closeAndNavigate}>
        <Image src={imagesData.banner} className="img"></Image>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={props.closeAndNavigate}
          style={{ backgroundColor: "var(--primary)", borderWidth: "0" }}
        >
          Enquiry Now
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
