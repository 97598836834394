import React from "react";
import "./faculty.css";
import { Col, Container, Image, Table } from "react-bootstrap";
import imagesData from "../../helper/imagesData";
import { facultyData } from "../../helper/helper";
import useMoveToTop from "../../helper/moveToTop";

export default function Faculty() {
  useMoveToTop();
  return (
    <div className="faculty">
      <div className="titleContainer">
        <h4 className="title">Faculty</h4>
      </div>
      <Container fluid="md">
        <Col className="imageCH">
          <Image src={imagesData.faculty} thumbnail className="img"></Image>
        </Col>
        <Col>
          <h5 className="paragraphDetail">
            Arya Adarsh Kullu has a strong faculty supported by a larger support
            staff. Arya Adarsh faculty broadens the learning experience. The
            highly-qualified and trained staff not only implements the
            curriculum but also breeds enthusiasm for learning and questioning.
            Each teacher's interaction with the students goes beyond the usual
            facilitator's areas of pastoral and parental concern. Teachers are
            totally involved with the children, in all academic and
            co-curricular activities.
          </h5>
        </Col>
        <br />
        <Col>
          <Table responsive>
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Name</th>
                <th>Designation/Qualification</th>
              </tr>
            </thead>
            <tbody>
              {facultyData.map((item) => {
                return (
                  <tr key={item.sr}>
                    <td>{item.sr}</td>
                    <td>{item.name}</td>
                    <td>{item.desDual}</td>
                  </tr>
                );
              })}
              <tr></tr>
            </tbody>
          </Table>
        </Col>
      </Container>
    </div>
  );
}
