import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./admission.css";
import { Container, Col, Image, Row, Form, Button } from "react-bootstrap";
import imagesData from "../../helper/imagesData";
import {
  toastSuccess,
  ToastContainerO,
  toastFailed,
  smtpJs,
  aboutPageData,
} from "../../helper/helper";
import Loader from "../../components/spinner/Loader";
import useMoveToTop from "../../helper/moveToTop";
import { CardComp } from "../../components";

export default function Admission() {
  const navigate = useNavigate();
  const classes = ["Nursery", "L.K.G", "U.K.G", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [submit, setSubmit] = useState(false);
  const [addmissionEnquiry, setAddmissionEnquiry] = useState({
    sName: "",
    fName: "",
    mName: "",
    class: "",
    phone: "",
    email: "",
    address: "",
  });

  useMoveToTop();

  const handleOnChange = (field, value) => {
    setAddmissionEnquiry({ ...addmissionEnquiry, [field]: value });
  };

  const onSubmit = (event) => {
    setSubmit(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    event.preventDefault();
    event.stopPropagation();
    console.log(addmissionEnquiry);
    const sub = "Mail from Website for Admission Enquiry";
    let body = `
      Student Name : ${addmissionEnquiry.sName} <br/>
      Father Name : ${addmissionEnquiry.fName} <br/>
      Mother Name : ${addmissionEnquiry.mName} <br/>
      Class : ${addmissionEnquiry.class} <br/>
      Phone : ${addmissionEnquiry.phone} <br/>
      Email : ${addmissionEnquiry.email} <br/>
      Address : ${addmissionEnquiry.address} <br/>
    `;
    smtpJs(sub, body)
      .then((message) => {
        console.log(message);
        toastSuccess();
      })
      .catch((err) => {
        console.log(err);
        toastFailed();
      })
      .finally(() => {
        setSubmit(true);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      });
  };

  return (
    <div className="admission">
      <div className="titleContainer">
        <h4 className="title">Admission</h4>
      </div>
      <Container fluid="md">
        <Col className="imageCH">
          <Image src={imagesData.addmission} thumbnail className="img"></Image>
        </Col>
        <br />
        <Row>
          <h5 className="paragraphDetail">
            Arya Adarsh High School is affiliated with HPBOSE for 9th & 10th
            Class level(Affiliation No. 630020) and also recognized through
            affiliation by Deputy Director of Elementary Education, Kullu
            (H.P.). The school provides education from class NURSERY to class
            10th.
          </h5>
        </Row>
        <br />
        <Row>
          <h5 className="paragraphDetail">
            <span className="primaryTextColor">Age limit & Seats :</span> The
            age for class NURSERY is 3+ years, on the basis of first come first
            serve and accordingly for classes LKG,UKG,I & II. For classes III
            onwards, the admission is granted if there is any vacant seat. The
            number of seats for each class is 30.
          </h5>
        </Row>
        <br />
        <Row>
          <h5 className="paragraphDetail">
            <span className="primaryTextColor">Examination Schedule :</span> The
            school is following the Examination for Classes IX &amp; X as per
            HPBOSE guidelines in the academic Session: 2022-2023. Term-1
            Examination will be held in the month of Nov., 2022. However, HPBOSE
            shall conduct an MCQ based objective examination in Term-1 for class
            X. Examination for Class IX shall be conducted by the school for
            which Question Papers shall be provided by HPBOSE. The Term-2 /
            Final Examination will be held in March-April 2023, which can be
            either subjective or objective as per guidelines of HPBOSE,
            Accordingly, the school is following the remodeled assessment
            structure, in our school for the other classes also i.e. from
            classes III to VIII. <br />
            <br />
            From classes Nursery to II: These classes have Academic Session in
            Four terms as per Elementary Education Department and also as per
            New Education Policy 2020. First: FA I in May, Second: FA II in
            July, In the month of September term examination SA I, Third: FA III
            in November, Forth: FA IV in January, Then final in the month of
            March Term Examination SA II. In every term, assessment of each
            curriculum viz: शैक्षिनिक स्थिति, उपस्थिति, स्वच्छता, गणवेश , आचरण,
            स्वास्थय, गृहकार्य एवं अनुशासन area and skills is made while
            children are involved in these activities. It is an ongoing process
            and no term-end evaluation is done i.e. there is no formal Unit Test
            or Examination in the form of Periodic Test or Terminal Examination.
          </h5>
        </Row>
        <br />
        <Row>
          <h5 className="paragraphDetail">
            <span className="primaryTextColor"> Academic Session :</span> 1st
            April to 31st March
          </h5>
          <br />
          <h5>
            <span className="primaryTextColor">Admission period :</span> 1st
            March to 30th June
          </h5>
          <h5>
            <span className="primaryTextColor">Winter Vacation :</span> As per
            as H.P. Govt. notification
          </h5>
          <h5>
            <span className="primaryTextColor">Monsoon Vacation :</span> As per
            as H.P. Govt. notification
          </h5>
          <h5 style={{ display: "flex" }}>
            <span className="primaryTextColor">Fee Structure : </span>
            <p
              style={{
                color: "var(--menuHoverColor)",
                marginLeft: "5px",
                cursor: "pointer",
              }}
              onClick={() =>
                window.open(
                  "https://drive.google.com/file/d/1pJbjTcWBxmyKenDqdRh8yVLPdxxbogwH/view?usp=sharing"
                )
              }
            >
              {" "}
              View
            </p>
          </h5>
        </Row>
        <br />
        <Row>
          <h5 style={{ textAlign: "center", color: "var(--primary)" }}>
            Admission Enquiry
          </h5>
        </Row>
        <br />
        <Form validated={false} onSubmit={onSubmit}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Student's Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Student Name"
              required
              onChange={(e) => handleOnChange("sName", e.target.value)}
              value={addmissionEnquiry.sName}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.selectControl1">
            <Form.Label>Enquiry for (Class)</Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => handleOnChange("class", e.target.value)}
            >
              <option>Open and Select Class</option>
              {classes.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </Form.Select>
            <br />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
            <Form.Label>Father's Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Father's Name"
              required
              onChange={(e) => handleOnChange("fName", e.target.value)}
              value={addmissionEnquiry.fName}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
            <Form.Label>Mother's Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Mother's Name"
              required
              onChange={(e) => handleOnChange("mName", e.target.value)}
              value={addmissionEnquiry.mName}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="phone"
              placeholder="9912345678"
              required
              value={addmissionEnquiry.phone}
              onChange={(e) => handleOnChange("phone", e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="example@gmail.com"
              value={addmissionEnquiry.email}
              onChange={(e) => handleOnChange("email", e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput6">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your present Address"
              value={addmissionEnquiry.address}
              onChange={(e) => handleOnChange("address", e.target.value)}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit" disabled={submit}>
            {submit ? <Loader /> : "Submit"}
          </Button>
        </Form>
        <br />
        <br />
        <br />
        <Col>
          <Row xs={1} md={2} className="g-4">
            {aboutPageData.map((item, index) => {
              return <CardComp key={index} item={item} />;
            })}
          </Row>
        </Col>
        <br />
      </Container>
      <ToastContainerO />
    </div>
  );
}
