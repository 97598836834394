import React from "react";
import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./header.css";

export default function Header() {
  return (
    <Navbar expand={"lg"} className="header" sticky="top" collapseOnSelect>
      <Container
        fluid
        style={{
          backgroundColor: "#FF4B2B",
          paddingLeft: "2%",
          paddingRight: "2%",
        }}
      >
        <Navbar.Toggle
          aria-controls={`offcanvasNavbar-expand-md}`}
          className="navbarIcon"
        />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-md`}
          aria-labelledby={`offcanvasNavbarLabel-expand-md`}
          placement="end"
          className="offCanvas"
        >
          <Offcanvas.Header closeButton variant="dark">
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
              Menu
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-center flex-grow-1 pe-3">
              <Nav.Link
                as={Link}
                to="/"
                className="text-white fontColor"
                eventKey="1"
              >
                HOME
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/about"
                className="text-white fontColor"
                eventKey="2"
              >
                ABOUT US
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/admission"
                className="text-white fontColor"
                eventKey="3"
              >
                ADMISSION
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/facilities"
                className="text-white fontColor"
                eventKey="4"
              >
                FACILITIES
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/principalmessage"
                className="text-white fontColor"
                eventKey="5"
              >
                PRINCIPAL MESSAGE
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/faculty"
                className="text-white fontColor"
                eventKey="6"
              >
                FACULTY
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/mission"
                className="text-white fontColor"
                eventKey="7"
              >
                VISION & MISSION
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/mandatory"
                className="text-white fontColor"
                eventKey="8"
              >
                MANDATORY DETAILS
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/contact"
                className="text-white fontColor"
                eventKey="9"
              >
                CONTACT US
              </Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
      <Container fluid className="secondBar">
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Navbar.Brand as={Link} to="/">
            <div className="logoBrandName">
              <img
                src="/logo5.png"
                width="70"
                height="70"
                className="d-inline-block align-top logo"
                alt="Arya Adarsh High School logo"
              />
            </div>
          </Navbar.Brand>
          <div className="logoBrandContainer">
            <p className="schoolName">ARYA ADARSH HIGH SCHOOL</p>
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <p className="affilation">AFFILATED TO H.P. BOARD</p>
            </div>
          </div>
          <div className="logoBrandNameD">
            <img
              src="./dayand.png"
              width="70"
              height="70"
              className="d-inline-block align-top logo"
              alt="Arya Adarsh High School Kullu"
            />
          </div>
        </div>
      </Container>
    </Navbar>
  );
}
