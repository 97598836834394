import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import imagesData from "../../helper/imagesData";

export default function AchievementCara() {
  const handleDragStart = (e) => e.preventDefault();
  const items = [
    <img
      src={imagesData.achievement1}
      onDragStart={handleDragStart}
      role="presentation"
      alt="first achivement"
    />,
    <img
      src={imagesData.achievement2}
      onDragStart={handleDragStart}
      role="presentation"
      alt="second achivement"
    />,
    <img
      src={imagesData.achievement3}
      onDragStart={handleDragStart}
      role="presentation"
      alt="third achivement"
    />,
    <img
      src={imagesData.achievement4}
      onDragStart={handleDragStart}
      role="presentation"
      alt="fourth  achivement"
    />,
  ];

  const responsive = {
    0: {
      items: 1,
      itemsFit: "contain",
    },
    500: {
      items: 2,
      itemsFit: "contain",
    },
    1024: {
      items: 3,
      itemsFit: "contain",
    },
  };

  return (
    <AliceCarousel
      mouseTracking
      items={items}
      autoPlay={true}
      infinite={true}
      animationDuration={2000}
      responsive={responsive}
      disableDotsControls={true}
      disableButtonsControls={false}
    />
  );
}
