import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "./carouselComp.css";
import { carouselData } from "../../helper/helper";

export default function CarouselComp() {
  return (
    <Carousel fade interval={800} variant="dark">
      {carouselData.map((item, index) => {
        return (
          <Carousel.Item key={index}>
            <img className="d-block  cImg" src={item.image} alt={item.alt} />
            <Carousel.Caption>
              <div className="captionCont">
                <h3 className="carasoulTitle">{item.title}</h3>
              </div>
              {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
            </Carousel.Caption>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}
