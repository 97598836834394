import React from "react";
import "./cardComp.css";
import { Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function CardComp({ item }) {
  return (
    <Col>
      <Card className="card">
        <Card.Header className="cardHeader">{item.header}</Card.Header>
        <Card.Img variant="top" src={item.img} className="cardImage" />
        <Card.Body>
          <Card.Text>
            {item.desc.slice(0, 110)}...{" "}
            <Card.Link as={Link} to={item.link}>
              Read more
            </Card.Link>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
}
