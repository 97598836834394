import React from "react";
import "./facilityCard.css";
import { Container, Col, Image, Row } from "react-bootstrap";

export default function FacilityCard({ title, desc, image, index }) {
  return (
    <div className="facilityCard">
      <div className="cardTitleContainer">
        <h6 className="title">{title}</h6>
      </div>
      <Container fluid="md">
        <Row xs={1} md={2}>
          {index % 2 === 0 && (
            <Col >
              <h6 className="desc">{desc}</h6>
            </Col>
          )}
          <Col>
            <Image src={image} thumbnail className="imgCard"></Image>
          </Col>
          {index % 2 === 1 && (
            <Col>
              <h6 className="desc">{desc}</h6>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
}
