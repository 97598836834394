import React from "react";
import { Container, Col, Image, Row } from "react-bootstrap";
import imagesData from "../../helper/imagesData";
import { principalPageData } from "../../helper/helper";
import CardComp from "../../components/card/CardComp";
import useMoveToTop from "../../helper/moveToTop";

export default function PrincipalMessage() {
  useMoveToTop();

  return (
    <div className="about">
      <div className="titleContainer">
        <h4 className="title">Principal's Message</h4>
      </div>
      <Container fluid="md">
        <Col className="imageCH">
          <Image src={imagesData.principal} thumbnail className="img"></Image>
        </Col>
        <Col>
          <h5 style={{ textAlign: "justify", marginBottom: "20px" }}>
            Welcome to the website of Arya Adarsh High School Akhara Bazar Kullu
            As you navigate your way through the site discovering what we have
            to offer, you will find answers to the many preliminary questions
            you may have about our school, our academic programme and student
            life. As the Principal of the school, I feel honoured and privileged
            to be part of an educational institution where every stakeholder is
            a learner and every day is an opportunity to learn and discover. We
            look at ourselves as a community of learners, where everyone learns
            including our teachers, parents & staff. <br />
            <br />I believe in upholding high standards with an absolute
            commitment to strive to understand and improve the educational
            process, using team strategies, while wholly centering on student
            achievement. Our staff fruitfully employ two diverse strategies that
            are of Love and Logic to foster a positive learning environment for
            all our students. Love and Logic may seem like two contrasting
            forces. While love helps nurture trusting relationships, where
            students feel respected, appreciated and loved by the teachers,
            logic helps develop in students personal responsibility, self
            control, good decision making skills self confidence, and character
            building with high moral values. Your child’s learning involves and
            revolves around an effective partnership between home & school. We
            know the stronger the partnership is the more your child will
            benefit. Whilst we know that the information here will give you
            answers to some of your questions, we also know that learning is
            social, and in this context we encourage you to come and speak to us
            in person for a more detailed perspective. I look forward to working
            with you as we help create a school where parents are welcome at
            anytime, students are engaged in meaningful learning and the staff
            members are valued and appreciated for their efforts.
          </h5>
          <h5>Suman Sood</h5>
          <h5>Principal</h5>
        </Col>
        <br />
        <br />
        <Col>
          <Row xs={1} md={2} className="g-4">
            {principalPageData.map((item, index) => {
              return <CardComp key={index} item={item} />;
            })}
          </Row>
        </Col>
        <br />
      </Container>
    </div>
  );
}
